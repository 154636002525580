<template>
  <div>
    <!-- Controllers -->
    <div color="white" class="d-flex justify-end py-4 pr-6">
      <v-btn color="primary" type="submit" class="mr-3" dark depressed @click.prevent="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-form>
        <v-row>
          <v-col md="8" sm="12">
            <v-row v-if="isAcademy" class="ma-0" style="display: block !important;">
              <v-icon
                color="primary"
                size="24"
              >
                lightbulb_outline
              </v-icon>
              {{ $t('project.academy.admissionDisclaimer') }}
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-4 d-flex">
                  <h3 class="mr-3" v-text="$t('project.editProject.emailSettings.banner')" />
                  <global-tooltip
                    :text="$t('common.tooltipImageSizes', {
                      proportion: '16:9',
                      width: '640px',
                      height: '360px'
                    })"
                  />
                </div>
                <image-field v-model="form.banner" :width="640" :height="360" class="my-2" can-be-deleted suffix="_emailBanner" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="mb-4" v-text="$t('project.editProject.emailSettings.emails.subscriberJoined')" />
                <v-text-field v-model="form.emails.subscriberJoined.subject" :label="$t('common.subject')" class="my-2" outlined dense />
                <html-field v-model="form.emails.subscriberJoined.body" :placeholder="$t('common.body')" class="my-2" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="mb-4" v-text="$t('project.editProject.emailSettings.emails.subscriberRejected')" />
                <v-text-field v-model="form.emails.subscriberRejected.subject" :label="$t('common.subject')" class="my-2" outlined dense />
                <html-field v-model="form.emails.subscriberRejected.body" :placeholder="$t('common.body')" class="my-2" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'EmailSettings',
  components: {
    HtmlField: () => import('@/components/formFields/HtmlField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters('project', ['data']),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
  },
  created() {
    this.form = {
      banner: this.data.emailSettings?.banner ?? null,
      emails: {
        subscriberJoined: {
          subject: this.data.emailSettings?.emails?.subscriberJoined?.subject ?? null,
          body: this.data.emailSettings?.emails?.subscriberJoined?.body ?? null,
        },
        subscriberRejected: {
          subject: this.data.emailSettings?.emails?.subscriberRejected?.subject ?? null,
          body: this.data.emailSettings?.emails?.subscriberRejected?.body ?? null,
        },
      },
    }
  },
  methods: {
    update() {
      const { organizationId, data, form } = this
      this.runAsync(() => this.$store.dispatch('project/updateEmailSettings', { organizationId, project: data, data: form }))
    },
  },
}
</script>
